import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { LAYERS } from '../../constants/parallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const DesertRoads: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural, bottomDivider }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Dividers.Gradient color={theme.colors.lightBlack} height={10} />
      <CSSParallax.Dividers.Gradient color={theme.colors.lightBlack} height={15} />
      <CSSParallax.Dividers.Gradient color={theme.colors.lightBlack} height={30} />
      <CSSParallax.Padder height={10} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={27} />
      {bottomDivider ? bottomDivider : <CSSParallax.Dividers.BrushStroke variant={1} color={theme.colors.blue} />}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={0} natural={natural}>
      <CSSParallax.Greeblies.Cactus1 size={17} bottom={8} right={-3} />
      <CSSParallax.Greeblies.Cactus1 size={22} bottom={9} left={-4} flipX={true} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-1} natural={natural}>
      <CSSParallax.Greeblies.Moon size={9.5} top={11} left={7} flipX={true} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-1} appearance={LAYERS.DESERT_ROAD_FRONT} natural={natural} />
    <CSSParallax.Layer depth={-3} natural={natural}>
      <CSSParallax.Greeblies.Moon2 size={6} top={16} left={14} flipX={true} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-3} appearance={LAYERS.DESERT_ROAD_BACK} natural={natural} />
    <CSSParallax.Layer depth={-4} appearance={LAYERS.GREEN_STARS} natural={natural} />
  </StyledSection>
);

export default DesertRoads;
