import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import StyledContentType from '../../components/CSSParallax/Content/types';

export const StyledContent = styled(CSSParallax.Content)(
  ({ pageHasAnnouncement }: StyledContentType) => `
    position: relative;
    min-height: 110vw;
    padding-top: ${pageHasAnnouncement ? '260' : '200'}px;

    @media screen and (orientation: portrait) {
      min-height: 100vh;
      padding-top: ${pageHasAnnouncement ? '200' : '140'}px;
    }
`,
);
