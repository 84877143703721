import React from 'react';
import ParallaxPage from '../../layout/components/ParallaxPage';
import BlackSection from '../../sections/Black';
import BlueSection from '../../sections/Blue';
import DesertRoadsSection from '../../sections/DesertRoads';
import FrontpageHeroSection from '../../sections/FrontpageHero';
import GreenStarsSection from '../../sections/GreenStars';
import PinkBeachSection from '../../sections/PinkBeach';
import PinkDesertSection from '../../sections/PinkDesert';
import PurpleSection from '../../sections/Purple';
import ToxicWastelandSection from '../../sections/ToxicWasteland';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';
import FrontPageWrapper from './styles';

class FrontPage extends ParallaxPage {
  pageSections = [
    BlackSection,
    PinkDesertSection,
    ToxicWastelandSection,
    PinkBeachSection,
    DesertRoadsSection,
    BlueSection,
    GreenStarsSection,
  ];

  renderChildren() {
    const { content } = this.props;

    const bottomDivider = <CSSParallax.Dividers.BrushStroke variant={4} color={theme.colors.purple} />;

    return (
      <FrontPageWrapper>
        {content &&
          content.contentBlocks &&
          content.contentBlocks.map((contentBlock, index) => {
            if (index === 0) {
              return (
                <FrontpageHeroSection key={index} pageHasAnnouncement={this.pageHasAnnouncement()}>
                  {this.renderBlock(contentBlock)}
                </FrontpageHeroSection>
              );
            } else if (index >= content.contentBlocks.length - 1) {
              return <PurpleSection key={index}>{this.renderBlock(contentBlock)}</PurpleSection>;
            }

            const Section = this.pageSections[(index - 1) % this.pageSections.length];

            return (
              <Section key={index} bottomDivider={index === content.contentBlocks.length - 2 ? bottomDivider : null}>
                {this.renderBlock(contentBlock)}
              </Section>
            );
          })}
      </FrontPageWrapper>
    );
  }
}

export default FrontPage;
