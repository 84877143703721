import styled from 'styled-components';
import { TitleWrapper, Title } from '../../blocks/Block/styles';
import { ColumnBlock, RowBlock } from '../../blocks/Block';
import { ThemeType } from '../../types';

const FrontPageWrapper = styled.div(
  ({ theme: { width } }: ThemeType) => `
    transform-style: preserve-3d;

    ${ColumnBlock} {
        ${TitleWrapper} {
            ${Title} {
                @media (min-width: ${width.tablet}) {
                    font-size: 64px;
                    line-height: 1;
                }                
            }
        }
    
        ${RowBlock} {
            ${Title} {
                @media (min-width:  ${width.tablet}) {
                    font-size: 56px;
                    line-height: 1;
                }                                
            }    
        }
    }
 `,
);

export default FrontPageWrapper;
