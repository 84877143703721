import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { LAYERS } from '../../constants/parallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const GreenStars: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural, bottomDivider }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Dividers.BrushStroke variant={2} color={theme.colors.green} flipY={true} />
      <CSSParallax.Padder height={5} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={8} />
      {bottomDivider ? bottomDivider : <CSSParallax.Dividers.BrushStroke variant={4} color={theme.colors.lightBlack} />}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={-4} appearance={LAYERS.GREEN_STARS} natural={natural} />
  </StyledSection>
);

export default GreenStars;
