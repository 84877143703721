import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';

export const StyledSection = styled(CSSParallax.Section)`
  background: linear-gradient(to bottom, ${theme.colors.green}, ${theme.colors.blue});
  background-color: ${theme.colors.blue};
  color: ${theme.colors.black3};
  position: relative;
  z-index: 3;
  min-height: 40vw;
  margin-bottom: -1px;
  margin-top: -1px;
  .title {
    color: ${theme.colors.black3};
  }
`;
