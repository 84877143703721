import styled, { css } from 'styled-components';
import { ThemeType } from '../../types';

interface Props extends ThemeType {
  image: string;
}

const logoWidth = 50;

export const StyledLogoImage = styled.h1(
  ({ image, theme: { mobileView } }: Props) => css`
  position: relative;
  display: block;
  font-size: 0;
  margin: 0 auto;
  width: ${logoWidth}vw;
  height: 0;
  padding-top: ${logoWidth * (1256 / 3790)}vw;
  ${image ? `background-image: url(${image});` : ''}
  ${image ? 'background-size: contain;' : ''}
  ${image ? 'background-repeat: no-repeat;' : ''}

  ${mobileView} {
    width: 75vw;
    padding-top: 24vw;
    ${image ? `background-image: url(${image});` : ''}
    ${image ? 'background-size: cover;' : ''}
    ${image ? 'background-repeat: no-repeat;' : ''}
    ${image ? 'background-position: center center;' : ''}
  }
`,
);

const textWidth = 46;

export const StyledTextImage = styled.h2(
  ({ image }: Props) => css`
    position: relative;
    display: block;
    font-size: 0;
    margin: -3vw auto 3vw;
    width: ${textWidth}vw;
    height: 0;
    padding-top: ${textWidth * (606 / 3116)}vw;
    ${image ? `background-image: url(${image});` : ''}
    ${image ? 'background-size: contain;' : ''}
    ${image ? 'background-repeat: no-repeat;' : ''}
  `,
);
