import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const PinkDesert: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural, bottomDivider }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Dividers.BrushStroke variant={3} color={theme.colors.lightBlack} flipY={true} />
      <CSSParallax.Padder height={10} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      {bottomDivider ? bottomDivider : <CSSParallax.Dividers.PinkDesert />}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={1} natural={natural}>
      <CSSParallax.Greeblies.Cactus1 size={22} bottom={-2.5} left={-4} flipX={true} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.WhiteSplatter1 top={-30} />
      <CSSParallax.Greeblies.WhiteSplatter2 bottom={-30} />
    </CSSParallax.Layer>
  </StyledSection>
);

export default PinkDesert;
