import styled from 'styled-components';
import CSSParallax from '../../components/CSSParallax';
import theme from '../../styles/theme';

export const StyledSection = styled(CSSParallax.Section)`
  background: linear-gradient(to bottom, ${theme.colors.pink}, ${theme.colors.babyPink});
  background-color: ${theme.colors.babyPink};
  color: ${theme.colors.white};
  position: relative;
  z-index: 3;
  margin-bottom: -1px;
  margin-top: -1px;
`;
