import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { Image as LogoImage, Text as LogoText } from '../../components/BigLogo';
import { LAYERS } from '../../constants/parallax';
import theme from '../../styles/theme';
import { StyledContent } from './styles';

const FrontpageHero: React.FunctionComponent<any> = ({ children, pageHasAnnouncement, natural }) => (
  <CSSParallax.Section natural={natural}>
    <StyledContent isFirstSection={true} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.ContentInner>
        <LogoImage />
        <LogoText />
        {children}
        <CSSParallax.Padder height={10} />
      </CSSParallax.ContentInner>
      <CSSParallax.Dividers.BrushStroke variant={2} color={theme.colors.lightBlack} />
    </StyledContent>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.GreenSplatter1 top={-20} />
      <CSSParallax.Greeblies.BlueSplatter1 top={-16} right={0} />
      <CSSParallax.Greeblies.WhiteSplatter3 top={52} />
      <CSSParallax.Greeblies.GreenSplatter2 top={65} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-1} appearance={LAYERS.ROCKY_ROAD_FRONT} natural={natural} />
    <CSSParallax.Layer depth={-1} natural={natural}>
      <CSSParallax.Greeblies.NearJerrySwarm />
      <CSSParallax.Greeblies.Moon size={15} top={28} right={7} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-2} natural={natural}>
      <CSSParallax.Greeblies.MidJerrySwarm />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-3} natural={natural}>
      <CSSParallax.Greeblies.FarJerrySwarm />
      <CSSParallax.Greeblies.Moon2 size={9.5} top={37} right={18} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-3} appearance={LAYERS.ROCKY_ROAD_BACK} natural={natural} />
    <CSSParallax.Layer depth={-4} appearance={LAYERS.WHITE_STARS} natural={natural} />
  </CSSParallax.Section>
);

export default FrontpageHero;
