import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { LAYERS } from '../../constants/parallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const PinkBeach: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural, bottomDivider }) => (
  <StyledSection>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Padder height={5} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Dividers.PinkBeachBack />
      {bottomDivider && bottomDivider}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.GreenSplatter3 top={-38} />
      <CSSParallax.Greeblies.PinkSplatter2 top={0} />
      <CSSParallax.Greeblies.WhiteSplatter4 bottom={-40} />
    </CSSParallax.Layer>
    {!bottomDivider && <CSSParallax.Layer depth={1} appearance={LAYERS.COOL_TV} natural={natural} />}
    {!bottomDivider && (
      <CSSParallax.Layer depth={1} appearance={LAYERS.PINK_BEACH} natural={natural}>
        <CSSParallax.Dividers.PinkBeachFront />
        <CSSParallax.Greeblies.Cactus3 size={17} bottom={16} left={-2} rotation={10} flipX={true} />
        <CSSParallax.Dividers.BrushStroke variant={2} color={theme.colors.lightBlack} />
      </CSSParallax.Layer>
    )}
  </StyledSection>
);

export default PinkBeach;
