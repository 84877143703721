import React from 'react';
import LogoImage from '../../assets/images/logo-sitg.png';
import TextImage from '../../assets/images/text-date-location.png';
import { StyledLogoImage, StyledTextImage } from './styles';

export const Image: React.FunctionComponent<any> = () => <StyledLogoImage image={LogoImage}>Splendour In The Grass</StyledLogoImage>;

export const Text: React.FunctionComponent<any> = () => (
  <StyledTextImage image={TextImage}>19, 20, 21 July 2019, North Byron Parklands</StyledTextImage>
);
