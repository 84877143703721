import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { LAYERS } from '../../constants/parallax';
import { StyledSection } from './styles';

const Blue: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural, bottomDivider }) => (
  <StyledSection natural={natural}>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Padder height={5} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={12} />
      {bottomDivider && bottomDivider}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={1} appearance={LAYERS.GALAXY_HOLE} natural={natural}>
      <CSSParallax.Greeblies.GalaxyHole size={28} right={-3} top={0} />
    </CSSParallax.Layer>
    {!bottomDivider && (
      <CSSParallax.Layer depth={1} natural={natural}>
        <CSSParallax.Greeblies.Cactus2 size={20} left={-2.5} bottom={-23} rotation={-15} flipX={true} />
      </CSSParallax.Layer>
    )}
    <CSSParallax.Layer depth={2} natural={natural}>
      <CSSParallax.Greeblies.GreenSplatter5 top={-40} />
      <CSSParallax.Greeblies.BlueSplatter3 bottom={-16} />
    </CSSParallax.Layer>
  </StyledSection>
);

export default Blue;
