import React from 'react';
import CSSParallax from '../../components/CSSParallax';
import { LAYERS } from '../../constants/parallax';
import theme from '../../styles/theme';
import { StyledSection } from './styles';

const ToxicWasteland: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement, natural, bottomDivider }) => (
  <StyledSection>
    <CSSParallax.Content isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement} natural={natural}>
      <CSSParallax.Padder height={17} />
      <CSSParallax.ContentInner>{children}</CSSParallax.ContentInner>
      <CSSParallax.Padder height={24} />
      {bottomDivider ? bottomDivider : <CSSParallax.Dividers.BrushStroke variant={1} color={theme.colors.pink} />}
    </CSSParallax.Content>
    <CSSParallax.Layer depth={2}>
      <CSSParallax.Greeblies.BlueSplatter3 bottom={-24} />
    </CSSParallax.Layer>
    <CSSParallax.Layer depth={-1} appearance={LAYERS.TOXIC_WASTELAND_FRONT} />
    <CSSParallax.Layer depth={-2} appearance={LAYERS.TOXIC_WASTELAND_MIDDLE} />
    <CSSParallax.Layer depth={-3} appearance={LAYERS.TOXIC_WASTELAND_BACK} />
    <CSSParallax.Layer depth={-4} appearance={LAYERS.GREEN_STARS} />
  </StyledSection>
);

export default ToxicWasteland;
